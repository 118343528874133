import { Heading } from '@unboared/base-ui.all'
import { TopRightContent } from '../../../../components/top_right_content'

export const TopRightQuestionIndex = ({
  index,
  total,
}: {
  index: number
  total: number
}) => {
  return (
    <TopRightContent>
      <Heading type="h2" text={`Question ${index + 1}/${total}`} />
    </TopRightContent>
  )
}
