import React from 'react'
import * as Linking from 'expo-linking'
import * as SplashScreen from 'expo-splash-screen'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import {
  ThemeProvider,
  unboaredDarkTheme,
  useScreenScale,
} from '@unboared/base-ui.all'

// ---- Import screens before authentication ----
import { Showcase } from './screens/showcase'
import { SignIn } from './screens/common/signin'
import { SignUp } from './screens/common/signup'
import { Auth } from './screens/common/auth'
import { EmailVerification } from './screens/common/email_verification'
import { UserSelectionAndLaunch } from './screens/user_selection/user_selection'

// ---- Import screens after authentication ----
import Home from './screens/home'
import SettingsScreen from './screens/settings'
import QuizzesScreen from './screens/quizzes'
import CreateQuizzScreen from './screens/create_quizz'
import ModidyQuizzScreen from './screens/modify_quizz'
import ScreenApp from './screens/screen_app'

// ---- Import screens for gamepads ----
import GamepadApp from './screens/gamepad_app'
import GamepadAccessPage from './screens/gamepad_access'

// ---- Import global states ----
import { useAuth, useAuthManager } from './services/auth'
import { useActiveUser, useActiveUserManager } from './services/user'

// ---- Other imports ----
import { GlobalPopup } from './components/popup/global_popup'

SplashScreen.preventAutoHideAsync()

// Configure paths to screens and gamepads
const config = {
  screens: {
    Me: '/me',
    ModifyQuizz: '/modify/:quizzID',
    Quizzes: '/quizzes/:userID',
    Quizz: '/quizz/:quizzID',
    Settings: '/settings',
    CreateQuizz: '/create',
    GamepadAccess: '/gamepad',
    Gamepad: '/gamepad/:sessionID',
    Home: '/',
    Auth: '/auth',
    EmailVerification: '/auth/mail',
    SignUp: '/signup',
    SignIn: '/login',
  },
}

// Configure linking
const linking = {
  prefixes: [Linking.createURL('/'), 'exp://'],
  config,
}

interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export default function App(props: NavigationProps) {
  // const colorScheme = Appearance.getColorScheme()
  useAuthManager()
  useScreenScale()

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          `UnQuizz • ${options?.title ?? route?.name}`,
      }}
      {...props}
    >
      <ThemeProvider theme={Object.assign({}, unboaredDarkTheme)}>
        <AppStack />
      </ThemeProvider>
    </NavigationContainer>
  )
}

// ----------- GENERAL APP STACK ---------------

const isAuthentified = (auth: any) => auth !== undefined && auth !== null

const AppStack = () => {
  // Gets the current auth
  const auth = useAuth((state) => state.auth)
  // Update the user data according to the authentified user
  useActiveUserManager(auth?.uid)

  // Check if initial loading
  const initialLoading = useAuth((state) => state.initialLoading)

  if (initialLoading) {
    return <></>
  }

  return !isAuthentified(auth) ? <AuthStack /> : <MainStack />
}

// ----------- AUTH STACK SECTION ---------------

const StackForAuth = createStackNavigator()

const AuthStack = () => {
  return (
    <>
      <GlobalPopup />
      <StackForAuth.Navigator
        initialRouteName="Home"
        screenOptions={{ headerShown: false }}
      >
        <StackForAuth.Screen name="Home" component={Showcase} />
        <StackForAuth.Screen
          name="SignUp"
          component={({ navigation }: any) => (
            <SignUp onHome={() => navigation.navigate('Home')} />
          )}
        />
        <StackForAuth.Screen
          name="SignIn"
          component={({ navigation }: any) => (
            <SignIn onHome={() => navigation.navigate('Home')} />
          )}
        />
        <StackForAuth.Screen
          name="GamepadAccess"
          component={GamepadAccessPage}
        />
        <Stack.Screen name="Gamepad" component={GamepadApp} />
        <StackForAuth.Screen name="CreateQuizz" component={CreateQuizzScreen} />
        <StackForAuth.Screen
          name="EmailVerification"
          component={EmailVerification}
        />
        <StackForAuth.Screen name="Auth" component={Auth} />
      </StackForAuth.Navigator>
    </>
  )
}

// ----------- MAIN STACK SECTION ---------------

const Stack = createStackNavigator()

const MainStack = () => {
  const { user } = useActiveUser()
  if (!user || !user.username) {
    return <UserSelectionAndLaunch />
  }
  return (
    <>
      <GlobalPopup />
      <Stack.Navigator
        initialRouteName="Me"
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen name="Me" component={Home} />
        <Stack.Screen name="GamepadAccess" component={GamepadAccessPage} />
        <Stack.Screen name="Gamepad" component={GamepadApp} />
        <Stack.Screen name="Quizzes" component={QuizzesScreen} />
        <Stack.Screen name="Quizz" component={ScreenApp} />
        <Stack.Screen name="Settings" component={SettingsScreen} />
        <Stack.Screen name="CreateQuizz" component={CreateQuizzScreen} />
        <Stack.Screen name="ModifyQuizz" component={ModidyQuizzScreen} />
      </Stack.Navigator>
    </>
  )
}
