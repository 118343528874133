import { useState } from 'react'
import { Platform } from 'react-native'
import { useLinkTo } from '@react-navigation/native'
import { Timestamp } from 'firebase/firestore'

import { useFullscreen } from '@unboared/utils.fullscreen'
import { SimpleActivityIndicator } from '@unboared/design.loaders'

import { DEFAULT_DURATION_BEFORE_START } from '../../const'

import { SignUp } from '../common/signup'
import SignIn from '../common/signin/signin'

import { useActiveUser } from '../../services/user'
import { usersAPI, useQuizz } from '../../services/users_api'
import { useAnalyticsAccessEvent } from '../../services/analytics'

import Container from '../../components/container/main_container'
import { ActivityIndicator } from '../../components/loaders'
import { useGlobalPopup } from '../../components/popup/global_popup'
import { DEFAULT_INITIAL_QUESTION } from '../../model/question/types'

import { QuizzEdition } from '../quizz_edition'
import { iOS } from '../../utils'

const initQuizz = (quizz) => {
  if (quizz) {
    return {
      title: quizz.infos.name,
      questions: quizz.questions,
      waitBeforeProposals:
        quizz.infos.waitBeforeProposals || DEFAULT_DURATION_BEFORE_START,
    }
  } else {
    return {
      title: '',
      questions: [DEFAULT_INITIAL_QUESTION],
      waitBeforeProposals: DEFAULT_DURATION_BEFORE_START,
    }
  }
}

export function ModifyQuizzFor({ navigation, quizz }: any) {
  const linkTo = useLinkTo()

  const { user: me } = useActiveUser() || undefined
  const currentQuizz = initQuizz(quizz)
  const { isFullscreen, toggleFullscreen } = useFullscreen()

  const { createUser } = useActiveUser()

  const addQuizzToDatabase = async (user: any) => {
    usersAPI.createQuizz({
      infos: {
        name: title,
        description: '',
        waitBeforeProposals: durationBeforeStart,
      },
      author: user.uid,
      first_release: Timestamp.fromDate(new Date()),
      last_update: Timestamp.fromDate(new Date()),
      questions: questions,
    })
  }

  const { open } = useGlobalPopup()
  const openSignIn = () =>
    open(
      <SignIn
        onSignup={openSignUp}
        onSuccessSignIn={(user: any) => {
          console.log('Create Quizz')
          addQuizzToDatabase(user)
            .then(() => {
              console.log('[SUCCESS] Save quizz.')
            })
            .catch((error) => {
              console.log(error)
              console.error('[ERROR] Unable to save quizz.')
            })
        }}
      />,
    )
  const openSignUp = () =>
    open(
      <SignUp
        onLogin={openSignIn}
        onSuccessSignUp={(user: any) => {
          console.log('Create User')
          console.log(user)
          createUser(user.uid)
            .then(() => {
              console.log('Create Quizz')
              addQuizzToDatabase(user)
                .then(() => {
                  console.log('[SUCCESS] Save quizz.')
                })
                .catch((error) => {
                  console.log(error)
                  console.error('[ERROR] Unable to save quizz.')
                })
            })
            .catch(() => {
              console.error('[ERROR] Unable to create user.')
            })
        }}
      />,
    )

  const [title, setTitle] = useState(currentQuizz.title)
  const [questions, setQuestions] = useState(currentQuizz.questions)
  const [durationBeforeStart, setDurationBeforeStart] = useState(
    currentQuizz.waitBeforeProposals,
  )
  const saveQuizz = (onSuccess: (quizzID?: string) => void) => {
    if (me) {
      if (quizz) {
        usersAPI
          .modifyQuizz({
            ...quizz,
            infos: {
              name: title,
              description: '',
              waitBeforeProposals: durationBeforeStart,
            },
            last_update: Timestamp.fromDate(new Date()),
            questions: questions,
          })
          .then(() => {
            onSuccess()
          })
          .catch(() => {
            console.error('[ERROR] Unable to save quizz.')
          })
      } else {
        usersAPI
          .createQuizz({
            infos: { name: title, description: '' },
            author: me.uid,
            first_release: Timestamp.fromDate(new Date()),
            last_update: Timestamp.fromDate(new Date()),
            questions: questions,
          })
          .then((quizzID) => {
            onSuccess(quizzID)
          })
          .catch((error) => {
            console.error(`[ERROR] Unable to create quizz - ${error}`)
          })
      }
    } else {
      openSignUp()
    }
  }

  const launchQuizz = () => {
    if (me) {
      saveQuizz((quizzID) => {
        if (Platform.OS == 'web' && !iOS() && !isFullscreen) {
          toggleFullscreen()
        }
        linkTo(`/quizz/${quizzID}`)
      })
    } else {
      openSignUp()
    }
  }

  return (
    <Container
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!currentQuizz ? (
        <ActivityIndicator />
      ) : (
        <QuizzEdition
          title={title}
          setTitle={setTitle}
          questions={questions}
          setQuestions={setQuestions}
          durationBeforeStart={durationBeforeStart}
          setDurationBeforeStart={setDurationBeforeStart}
          onCancel={() => navigation.goBack()}
          onSave={() => {
            saveQuizz(() => {
              linkTo(`/me`)
            })
          }}
          onLaunch={launchQuizz}
        />
      )}
    </Container>
  )
}

export function ModifyQuizzScreen({ navigation, route }: any) {
  useAnalyticsAccessEvent('modify_quizz')

  const { quizzID } = route.params
  const quizz = useQuizz(quizzID)

  if (!quizz) {
    return (
      <Container
        style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
      >
        <SimpleActivityIndicator />
      </Container>
    )
  }

  return <ModifyQuizzFor navigation={navigation} route={route} quizz={quizz} />
}
