import {
  Heading,
  size,
  space,
  Text,
  transparency,
  useNormalize,
} from '@unboared/base-ui.all'
import { View } from 'react-native'
import { QR } from './qr'
import { Question } from '../Question'

export const QrAnswer = ({
  question,
  answers,
}: {
  question: Question
  answers: any
}) => {
  let qr = question as QR
  const { normalize } = useNormalize()

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '80%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Heading
          type="h3"
          style={{ fontFamily: 'OpenSansBold', fontSize: normalize(15) }}
        >
          {'La réponse était : '}
          <Heading type="h1" text={qr.getAnswer()} />
        </Heading>
      </View>
      {qr.getAnecdote() && (
        <View
          style={{
            maxWidth: '80%',
            marginTop: normalize(space.small),
            borderRadius: normalize(size.borderRadius.small),
            padding: normalize(space.smaller),
            backgroundColor: transparency('dark', 0.2),
          }}
        >
          <Text
            style={{ textAlign: 'center' }}
            numberOfLines={5}
            preset="big"
            text={`💡 ${qr.getAnecdote()}`}
          />
        </View>
      )}
    </View>
  )
}
