import { useEffect } from "react";

/** This hook prevent pull to refresh */
export default () => {
    useEffect(() => {
        window.addEventListener('beforeunload', (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = 'Exit the quizz ?';
        });
    }, [])

}