import { Quizz } from "./Quizz"
import { QuestionConverter } from "../question"

/**
 * Convert a data structure for the application from/to a data structure for the database.
 * Here we convert quizz types.
 */
export const QuizzConverter = {
    toDatabase: (quizz: Quizz): any => {
        let questions = [];
        for (let i = 0; i < quizz.getNumQuestions(); i++) {
            questions.push(QuestionConverter.toDatabase(quizz.getQuestion(i)))
        }
        return {
            infos: {
                name: quizz.getName(),
                description: quizz.getDescription(),
                waitBeforeProposals: quizz.getWaitBeforeProposals(),
            },
            questions: questions,
        }
    },

    fromDatabase: (data: any): Quizz => {
        let quizz = new Quizz(data.infos.name, data.infos.description, data.infos.waitBeforeProposals)
        for (let q of data.questions) {
            quizz.addQuestion(QuestionConverter.fromDatabase(q))
        }
        return quizz
    }
}