import { View } from 'react-native'
import { Heading, space, useNormalize } from '@unboared/base-ui.all'
import { QCU } from './qcu'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { Question } from '../Question'

export const QcuProposals = ({ question }: { question: Question }) => {
  let qcm = question as QCU

  const { normalize } = useNormalize()
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {qcm.getProposals().length > 0 &&
        qcm.getProposals().map((proposal, i) => (
          <View
            key={i}
            style={{ width: '50%', padding: normalize(space.smaller) }}
          >
            <ProposalButton
              id={i}
              // disable={status == 'results' && currentTrack.id !== proposal.id}
            >
              <Heading
                type="h3"
                style={{ fontFamily: 'OpenSansBold', fontSize: normalize(15) }}
                text={proposal}
                numberOfLines={3}
                ellipsizeMode="tail"
              />
            </ProposalButton>
          </View>
        ))}
    </View>
  )
}
