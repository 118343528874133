import { useNormalize } from '@unboared/utils.scale'
import * as React from 'react'
import { Text, View, StyleSheet, ScrollView } from 'react-native'

import { DraxProvider, DraxList } from 'react-native-drax'
import { NavigationItem } from './navigation_item'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

export const NavigationPanel2 = ({
  numQuestions,
  activeQuestion,
  onPressTitle,
  addQuestion,
  setIndexQuestion,
  removeQuestion,
  moveQuestion,
}: any) => {
  const { normalize } = useNormalize()
  return (
    <ScrollView
      style={{
        width: '100%',
        maxHeight: normalize(40),
        zIndex: 0,
      }}
      horizontal
    >
      <DraxProvider>
        <View style={styles.container}>
          <NavigationItem text="TITRE" onPress={onPressTitle} />
          <DraxList
            data={Array.from(Array(numQuestions).keys())}
            renderItemContent={({ item }) => (
              <NavigationItem
                key={item}
                text={`Q${item + 1}`}
                numQuestions={numQuestions}
                onRemove={removeQuestion}
                onPress={() => setIndexQuestion(item)}
                active={activeQuestion == item}
              />
            )}
            onItemReorder={({ fromIndex, toIndex }) =>
              moveQuestion(fromIndex, toIndex)
            }
            keyExtractor={(item) => item}
            horizontal
          />
          <NavigationItem text="+" onPress={addQuestion} />
        </View>
      </DraxProvider>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
