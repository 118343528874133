import { DEFAULT_QUESTION_DURATION } from "../../../const"
import { QCU } from "../qcu"
import { QuestionType } from "../types"

/**
 * The model related to the "Vrai/Faux".
 */
export class VraiFaux extends QCU {
    constructor(question: string, duration: number = DEFAULT_QUESTION_DURATION, anecdote: string = "", answer: string = 'vrai') {
        super(question, duration, anecdote, answer, ['vrai', 'faux'])
    }

    getType(): QuestionType {
        return QuestionType.VRAI_FAUX
    }
}