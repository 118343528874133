import { DEFAULT_DURATION_BEFORE_START } from "../../const"
import { Question } from "../question/Question"
import { QuestionType } from "../question/types"

/**
 * This quizz class is used in the application.
 */
export class Quizz {
    name: string
    description: string
    waitBeforeProposals: number = DEFAULT_DURATION_BEFORE_START
    questions: Array<Question> = []

    constructor(name: string = "", description: string = "", waitBeforeProposals?: number, questions?: Array<Question>) {
        this.name = name
        this.description = description
        if (questions) {
            this.questions = questions
        }
        if (waitBeforeProposals) {
            this.waitBeforeProposals = waitBeforeProposals
        }
    }

    setName(name: string) {
        this.name = name
    }

    setDescription(description: string) {
        this.description = description
    }

    getName() {
        return this.name
    }

    getDescription() {
        return this.description
    }

    getNumQuestions() {
        return this.questions.length
    }
    getWaitBeforeProposals(): number {
        return this.waitBeforeProposals
    }

    getQuestionType(i: number): QuestionType {
        return this.getQuestion(i).getType()
    }

    getQuestionDuration(i: number) {
        return this.getQuestion(i).getDuration()
    }

    getQuestionStatement(i: number) {
        return this.getQuestion(i).getQuestion()
    }

    getQuestion(i: number) {
        if (this.__checkQuestionIndex(i)) {
            return this.questions[i]
        }
        else {
            throw `[ERROR] Wrong question index ${i}`
        }
    }

    checkAnswer(i: number, answer: unknown) {
        return this.getQuestion(i).checkAnswer(answer)
    }

    addQuestion(question: Question) {
        this.questions.push(question)
    }

    removeQuestion(i: number) {
        this.questions.splice(i, 1)
    }

    __checkQuestionIndex(i: number) {
        return (i >= 0 && i < this.questions.length)
    }

}
