import { Heading, space, useNormalize } from '@unboared/base-ui.all'
import { TimerBar } from '@unboared/design.all'
import { View } from 'react-native'
import { UnQuizzTimer } from '../../../../components/quizz/countdown_timer/countdown_timer'
import { Quizz } from '../../../../model/quizz/Quizz'
import { TopRightQuestionIndex } from './show_question_index'

export const ShowProposalsScreen = ({
  quizz,
  indexQuestion,
  startNextState,
}: {
  quizz: Quizz
  indexQuestion: number
  startNextState: () => void
}) => {
  const { normalize } = useNormalize()
  let ProposalsComponent = quizz
    .getQuestion(indexQuestion)
    .getProposalsComponent()

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TopRightQuestionIndex
        index={indexQuestion}
        total={quizz.getNumQuestions()}
      />
      <View
        style={{ flex: 0.4, alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <View style={{ marginBottom: normalize(space.medium) }}>
          <UnQuizzTimer
            duration={quizz.getQuestionDuration(indexQuestion)}
            onComplete={startNextState}
          />
        </View>
        <Heading
          style={{
            textAlign: 'center',
            marginBottom: normalize(space.medium),
          }}
          text={quizz.getQuestionStatement(indexQuestion)}
        />
      </View>
      <View style={{ flex: 0.6, alignSelf: 'stretch', alignItems: 'center' }}>
        <ProposalsComponent question={quizz.getQuestion(indexQuestion)} />
      </View>
    </View>
  )
}
