/* METADATA */
export const GAME_NAME = 'unquizz';
export const DEFAULT_SCENE = 'connexion';
export const DEBUG = false
export const PROD_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com/'; 

/**
 * Add '/gamepad' at the end of the url. 
 * If the url is 'http://my-screen.com', then this function 
 * will return 'http://my-screen.com/gamepad'
 * @param baseURL the base url
 * @returns the default gamepad url
 */
export const computeDefaultGamepadURL = (baseURL: string) => {
  return baseURL + (baseURL.charAt(baseURL.length - 1) !== '/' ? '/' : '') + 'gamepad';
}

/**
 * Gets the gamepad URL.
 * @param host the host
 * @param sessionID the sessionID
 * @returns the gamepad url
 */
export const getGamepadURL = (host: string, sessionID: number | string) => `${host}/gamepad/${sessionID}`;

/**
 * Gets the gamepad URL format.
 * @param host the host
 * @returns the gamepad url format
 */
export const getGamepadURLFormat = (host: string) => getGamepadURL(host, '{sessionID}');

