import React, { useRef } from 'react'
import { Platform } from 'react-native'
import SplashScreen from '../../../assets/video/4hours.mp4'

/**
 * This component is used to prevent the device from going into sleep mode.
 * The solution found for this is to play a 4 hours video in the background.
 * Loop the same video does'nt work on old version of iOS.
 */
export const NoSleepView = () => {
  const videoRef = useRef()

  if (Platform.OS === 'web') {
    return (
      <video
        ref={videoRef}
        style={{
          // display: 'none',
          // zIndex:99
          position: 'fixed',
          right: 0,
          bottom: 0,
        }}
        src={SplashScreen}
        width="1"
        height="1"
        // loop
        autoPlay
        playsInline
        webkit-playsinline="true"
        muted
        // controls
      ></video>
    )
  }

  return null
}
