import React from 'react'
import GamepadContainer from '../../components/container/gamepad_container'
import QuizzGamepadApp from '../../quizz/gamepad_app'
import { useAnalyticsAccessEvent } from '../../services/analytics'
import { NoSleepView } from '../../components/view/no_sleep'
import useUnloadPrevent from '../../hooks/useUnloadPrevent'

/* The main gamepad app (in game) */
export function GamepadApp(props: any) {
  const sessionID = props.route.params.sessionID
  useAnalyticsAccessEvent('gamepad')
  useUnloadPrevent()

  return (
    <GamepadContainer>
      <NoSleepView />
      <QuizzGamepadApp sessionID={sessionID} />
    </GamepadContainer>
  )
}
