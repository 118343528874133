import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { useCustomDeviceStateProperty, usePlayers } from '@unboared/lib'

import {
  PortraitMobileLayout,
  Heading,
  size as scaleSize,
  Text,
  useNormalize,
  Button,
} from '@unboared/base-ui.all'
import { useLinkTo } from '@react-navigation/native'
// import { UnboaredPageLoader } from '../components/loader'

const ScoreSection = ({
  rank,
  activePlayers,
  score,
  maxScore,
  size,
}: {
  rank: number
  activePlayers: number
  score: number
  maxScore: number
  size: number
}) => {
  const message =
    (rank <= 3 && activePlayers > 3) ||
    (activePlayers <= 3 && rank === 1 && activePlayers > 1) ||
    (activePlayers === 1 && score > 0.85 * maxScore)
      ? 'Well Done !'
      : 'Try again !'
  const { normalize } = useNormalize()
  const { getColor } = usePlayers()

  const scoreStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: getColor(),
    borderRadius: normalize(scaleSize.borderRadius.small),
    height: normalize(size),
    width: normalize(size),
    marginBottom: normalize(size / 3),
  }

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <View style={scoreStyle}>
        <Heading
          style={{
            fontSize:
              activePlayers === 1
                ? normalize(0.36 * size)
                : normalize(0.6 * size),
          }}
          text={
            activePlayers === 1
              ? `${score} / ${maxScore}` // on montre le pourcentage si joueur seul
              : `#${rank}` // on montre le classement sinon
          }
        />
      </View>
      <Heading text={message} />
      <Text style={{ fontSize: normalize(14) }} text={`${score} pts`} />
    </View>
  )
}

const CreateYourOwnQuizz = () => {
  const linkTo = useLinkTo()

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button onPress={() => linkTo('/create')}>
        Je crée mon quizz gratuit
      </Button>
    </View>
  )
}

const Gamepad = () => {
  const content = <CreateYourOwnQuizz />
  const { getDeviceID, getHostID } = usePlayers()
  const hostID = getHostID()
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty()
  const [score, setScore] = useState<number>()
  const [rank, setRank] = useState<number>()
  const sortedPlayers = getCustomDeviceStateProperty(hostID, 'sortedPlayers')
  const numQuestions = getCustomDeviceStateProperty(hostID, 'numQuestions')

  useEffect(() => {
    const updateScores = () => {
      if (sortedPlayers) {
        for (let i = 0; i < sortedPlayers.length; i++) {
          if (sortedPlayers[i].deviceID === getDeviceID()) {
            setScore(sortedPlayers[i].score)
            setRank(i + 1)
          }
        }
      }
    }
    updateScores()
  }, [numQuestions, sortedPlayers])

  if (score === undefined || rank === undefined) {
    return <></> // ADD LOADER
  }

  // if (!isPortrait) {
  //     return <ChangeScreenOrientationPage to='portrait' />
  // }
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 0.7 }}>
        <ScoreSection
          rank={rank}
          score={score}
          maxScore={numQuestions}
          activePlayers={sortedPlayers.length}
          size={55}
        />
      </View>
      <View style={{ flex: 0.3 }}>{content}</View>
    </View>
  )
}

export default Gamepad
