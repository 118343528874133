import { QCM, QCU, Question, QuestionData, VraiFaux, QR } from "."

/**
 * Convert a data structure for the application from/to a data structure for the database.
 * Here we convert question types.
 */
export const QuestionConverter = {
    toDatabase: (question: Question): QuestionData => {
        return { ...question }
    },
    fromDatabase: (data: any): Question => {
        switch (data.type) {
            case 'qcu':
                return new QCU(data.question, data.duration, data.anecdote, data.answer, data.proposals)
            case 'qcm':
                return new QCM(data.question, data.duration, data.anecdote, data.answer, data.proposals)
            case 'vrai_faux':
                return new VraiFaux(data.question, data.duration, data.anecdote, data.answer)
            case 'qr':
                return new QR(data.question, data.duration, data.anecdote, data.answer, [])
            default:
                throw `Unknown question type: ${data.type}`
        }
    }
}