import {
  Heading,
  size,
  space,
  Text,
  transparency,
  useNormalize,
} from '@unboared/base-ui.all'
import { View } from 'react-native'
import { QCU } from './qcu'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { Question } from '../Question'
import { useEffect, useState } from 'react'

export const QcuAnswer = ({
  question,
  answers,
}: {
  question: Question
  answers: any
}) => {
  let qcu = question as QCU
  const { normalize } = useNormalize()
  const [counts, setCounts] = useState<Array<number>>([])

  useEffect(() => {
    if (qcu && answers) {
      const n_proposals = qcu.getProposals().length
      let counts = Array(n_proposals).fill(0)
      for (let i = 0; i < n_proposals; i++) {
        counts[i] = Object.values(answers).filter(
          (a: unknown) => a === qcu.getProposal(i),
        ).length
      }
      setCounts(counts)
    }
  }, [qcu, answers])

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {qcu.getProposals().length > 0 &&
        qcu.getProposals().map((proposal, i) => (
          <View
            key={i}
            style={{ width: '50%', padding: normalize(space.smaller) }}
          >
            <ProposalButton
              id={i}
              disable={!qcu.includeAnswer(qcu.getProposal(i))}
            >
              <Heading
                type="h3"
                style={{ fontFamily: 'OpenSansBold', fontSize: normalize(15) }}
                text={proposal as string}
                numberOfLines={2}
                ellipsizeMode="tail"
              />
              {counts.length > i && (
                <Heading type="h1" text={counts[i].toString()} />
              )}
            </ProposalButton>
          </View>
        ))}
      {qcu.getAnecdote() && (
        <View
          style={{
            maxWidth: '80%',
            marginTop: normalize(space.small),
            borderRadius: normalize(size.borderRadius.small),
            padding: normalize(space.smaller),
            backgroundColor: transparency('dark', 0.2),
          }}
        >
          <Text
            style={{ textAlign: 'center' }}
            numberOfLines={5}
            preset="big"
            text={`💡 ${qcu.getAnecdote()}`}
          />
        </View>
      )}
    </View>
  )
}
