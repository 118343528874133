import { View } from 'react-native'
import styled from 'rn-css'
import { Player } from '@unboared/native.types'
import { Avatar } from '@unboared/design.all'
import {
  Heading,
  Text,
  unboaredDarkTheme,
  useNormalize,
} from '@unboared/base-ui.all'

export type ScorePositionProps = ScoreProps & {
  position: number
}

const PodiumScore = ({
  position,
  player,
  score,
  size,
  maxScore,
  showPosition = true,
}: ScorePositionProps) => {
  const { normalize } = useNormalize()

  return (
    <View style={{ alignItems: 'center' }}>
      <Avatar name={player.avatar} color={player.color} size={size} />
      {showPosition && (
        <PositionIcon
          position={position}
          size={normalize(23)}
          avatarSize={normalize(size)}
          color={player.color}
          textColor={'#FFFFFF'}
          borderColor={unboaredDarkTheme.backgroundColor}
        />
      )}
      <Heading type="h3" text={player.username} />
      <Heading type="h3" text={`${score}${maxScore ? ` / ${maxScore}` : ''}`} />
    </View>
  )
}

const PositionIcon = ({
  position,
  color,
  borderColor,
  textColor,
  size,
  avatarSize,
}: {
  position: number
  color: string
  borderColor: string
  textColor: string
  size: number
  avatarSize: number
}) => {
  return (
    <EditIconContainer
      avatarSize={avatarSize}
      backgroundColor={color}
      borderColor={borderColor}
      size={size}
    >
      <Text
        text={`${position}`}
        style={{
          color: textColor,
          fontFamily: 'OpenSansSemiBold',
          fontSize: 0.6 * size,
        }}
      />
    </EditIconContainer>
  )
}

const EditIconContainer = styled.View<{
  backgroundColor: string
  borderColor: string
  size: number
  avatarSize: number
}>`
  position: absolute;
  top: ${(props) => -props.size / 2};
  left: ${(props) => (props.avatarSize - props.size) / 2};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.size / 8} solid ${(props) => props.borderColor};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: ${(props) => props.size};
  justify-content: center;
  align-items: center;
`

export type ScoreProps = {
  player: Player
  score: number
  maxScore?: number
  size: number
  showPosition?: boolean
}

export const FirstPositionScore = (props: ScoreProps) => (
  <PodiumScore position={1} {...props} />
)
export const SecondPositionScore = (props: ScoreProps) => (
  <PodiumScore position={2} {...props} />
)
export const ThirdPositionScore = (props: ScoreProps) => (
  <PodiumScore position={3} {...props} />
)

export default PodiumScore
