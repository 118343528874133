import React from 'react'
import Container from '../../components/container/main_container'
import { QuizzConverter } from '../../model/quizz'
import QuizzScreenApp from '../../quizz/screen_app'
import { useAnalyticsAccessEvent } from '../../services/analytics'
import { useQuizz } from '../../services/users_api/users_api'
import { NoSleepView } from '../../components/view/no_sleep'

/* The main screen app (in game) */
export function ScreenApp(props: any) {
  useAnalyticsAccessEvent("quizz/start")
  const quizzID = props.route.params.quizzID
  const quizzDB = useQuizz(quizzID)
  const quizz = quizzDB ? QuizzConverter.fromDatabase(quizzDB) : undefined

  return (
    <Container>
      <NoSleepView />
      <QuizzScreenApp quizz={quizz} />
    </Container>
  )
}
