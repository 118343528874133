import { StyleSheet, View } from 'react-native'
import HomeContainer from '../../../../components/container/home_container'
import {
  accent_color,
  Button,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all'
import { useState } from 'react'

export const DeleteConfirmationPopup = ({
  name,
  numQuestions,
  onRemove,
  onCancel,
}: any) => {
  const { normalize } = useNormalize()

  if (numQuestions < 2) {
    return (
      <HomeContainer>
        <View style={styles.container}>
          <Heading type="h2">Supression impossible</Heading>
          <Heading type="h3" style={{ color: accent_color.important }}>
          C'est la seule question
        </Heading>
          <Text
            style={{
              maxWidth: normalize(300),
              marginVertical: normalize(space.small),
            }}
          >
            Un quizz doit contenir{' '}
            <Text style={{ fontWeight: 'bold' }}>au moins une question</Text>.
          </Text>
          <Button text="Fermer" onPress={onCancel} />
        </View>
      </HomeContainer>
    )
  }

  return (
    <HomeContainer>
      <View style={styles.container}>
        <Heading type="h2">Supprimer la question</Heading>
        <Heading type="h3" style={{ color: accent_color.important }}>
          {name}
        </Heading>
        <Text
          style={{
            maxWidth: normalize(300),
            marginVertical: normalize(space.small),
          }}
        >
          En cliquant sur le bouton ci-dessous, vous confirmez vouloir supprimer
          définitivement la question{' '}
          <Text style={{ fontWeight: 'bold'}}>{name}</Text>.
        </Text>
        <Button
          preset={'primary'}
          text="Je souhaite supprimer cette question"
          onPress={onRemove}
        />
      </View>
    </HomeContainer>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
