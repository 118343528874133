import React from 'react'
import { Pressable, View } from 'react-native'

import { Button } from '@unboared/base-ui.buttons.button'
import { ConnexionInstructions, Player } from '@unboared/design.all'
import {
  useNormalize,
  Heading,
  space,
  accent_color,
  Text,
  useTranslate,
} from '@unboared/base-ui.all'
import { useUnboared } from '@unboared/lib'
import { useLinkTo } from '@react-navigation/native'

/**
 * The connexion screen.
 */
const Screen = ({ quizz }: any) => {
  const { normalize } = useNormalize()
  const unboared = useUnboared()
  const linkTo = useLinkTo()

  const startGame = () => {
    unboared.loadScene('inQuizz')
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <ConnexionInstructions
        style={{
          flex: 1,
        }}
        url={unboared.getSessionLink()}
        roomID={unboared.getSessionID()}
        connectedPlayers={[]}
        masterID={''}
        test
      />
      <ConnexionIntructionsInfosPlayers
        connectedPlayers={unboared.getPlayers()}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Pressable
          onPress={() => linkTo('/me')}
          style={{ width: '50%', alignItems: 'center' }}
        >
          <Heading type="h3">Annuler</Heading>
        </Pressable>
        <Button
          textPreset="medium"
          style={{
            marginLeft: normalize(space.small),
            backgroundColor: accent_color.important,
            width: '50%',
          }}
          text="Démarrer le Quizz"
          onPress={startGame}
          size={12}
        />
      </View>
    </View>
  )
}

export const ConnexionIntructionsInfosPlayers = ({ connectedPlayers }) => {
  const { translate } = useTranslate()
  const { normalize } = useNormalize()

  return (
    <View style={{ flex: 0.5, alignItems: 'center' }}>
      <Text>
        {`${connectedPlayers.length} ${translate(
          'screen.connexionPage.connectedPlayers',
        )}`}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {connectedPlayers.map((player, index) => (
          <Player
            key={index}
            style={{ margin: normalize(space.smaller) }}
            username={player.username}
            avatar={player.avatar}
            color={player.color}
            size={30}
          />
        ))}
      </View>
    </View>
  )
}

export default Screen
