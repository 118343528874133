import { QuestionType } from "./types";
import { QCM } from "./qcm";
import { QCU } from "./qcu";
import { QR } from "./qr";
import { VraiFaux } from "./vrai_faux";

export const makeQuestion = (type: QuestionType, data: Object) => {
    let model;
    switch (type) {
        case QuestionType.QCM:
            model = new QCM("", 0, "", [], []);
            break;
        case QuestionType.QCU:
            model = new QCU("", 0, "", "", []);
            break;
        case QuestionType.VRAI_FAUX:
            model = new VraiFaux("", 0, "", "");
            break;
        case QuestionType.QR:
            model = new QR("", 0, "", "", [])
        default:
            break;
    }
    if (!model) {
        throw `Type error: ${type} does not exists.`
    }
    return Object.assign(model, data);
}