import { View } from "react-native"
import { Avatar } from '@unboared/design.all'
import { Heading, unboaredDarkTheme, size as scaleSize, space, transparency, useNormalize } from "@unboared/base-ui.all"

import { ScorePositionProps } from "./PodiumScore"

const PositionScore = ({ position, player, score, size, width = 350 }: ScorePositionProps & { width?: number }) => {
    const { normalize } = useNormalize()

    const containerStyle = {
        width: normalize(width),
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: transparency('dark', 0.1),
        borderRadius: normalize(scaleSize.borderRadius.small),
        padding: normalize(space.smaller),
        margin: normalize(space.tiny),
    }

    const scoreStyle = {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: player.color,
        borderRadius: normalize(scaleSize.borderRadius.small),
        width: normalize(size)
    }

    const usernameStyle = {
        justifyContent: "center",
        width: normalize(width - 3 * size)
    }

    return (
        <View style={containerStyle}>
            <Avatar name={player.avatar} color={player.color} size={size} />
            <View style={usernameStyle}>
                <Heading type="h3" text={`#${position}  ${player.username}`} />
            </View>
            <View style={scoreStyle}>
                <Heading type="h3" text={`${score}`} numberOfLines={1} />
            </View>
        </View>
    )
}


export default PositionScore;