import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'

import {
  useCommunication,
  useCustomDeviceStateProperty,
  usePlayers,
} from '@unboared/lib'

import { useNormalize } from '@unboared/utils.scale'
import { useScreenInfos } from '@unboared/utils.orientation'

import { UnQuizzState } from './components/UnQuizz'
import { Logo } from '../../components/logo'
import { Question, makeQuestion } from '../../model/question'

import goodAnswerImg from '../../assets/img/goodAnswer.png'
import badAnswerImg from '../../assets/img/badAnswer.png'

const Gamepad = () => {
  const { emitAction } = useCommunication()
  const { normalize } = useNormalize()

  const { isPortrait } = useScreenInfos()

  const [vote, setVote] = useState<unknown>(undefined)
  const [canVote, setCanVote] = useState(false)
  const [question, setQuestion] = useState<Question>()
  const [gameState, setGameState] = useState<UnQuizzState>()

  const { getHostID } = usePlayers()
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty()
  let currentState: UnQuizzState, currentQ: any

  try {
    currentState = getCustomDeviceStateProperty(getHostID(), 'state')
  } catch {
    currentState = UnQuizzState.SHOW_READY
  }
  try {
    currentQ = getCustomDeviceStateProperty(getHostID(), 'question')
  } catch {
    currentQ = undefined
  }

  let QcmGamepad = question?.getGamepadComponent()

  // useEffect(() => {
  //   onCustomDeviceStatePropertyChange(api, (deviceID: string, key: string) => {
  //     console.log('State changed - ', key)
  //     if (key === 'state') {
  //       const state = api().getCustomDeviceStateProperty(deviceID, key)
  //       setGameState(state)
  //       setCanVote(state === UnQuizzState.SHOW_PROPOSALS)
  //     } else if (key === 'question') {
  //       let qData = api().getCustomDeviceStateProperty(deviceID, key)
  //       console.log(`Question (${qData.type})`)
  //       console.log(qData)
  //       setQuestion(makeQuestion(qData.type, qData))
  //     }
  //   })
  // }, [])

  useEffect(() => {
    if (currentState) {
      setGameState(currentState)
      setCanVote(currentState === UnQuizzState.SHOW_PROPOSALS)
    }
  }, [currentState])

  useEffect(() => {
    if (currentQ !== undefined) {
      setQuestion(makeQuestion(currentQ.type, currentQ))
    }
  }, [currentQ])

  const onVote = (ans: unknown) => {
    if (vote === undefined && canVote) {
      setVote(ans)
      emitAction('vote', { vote: ans })
    }
  }

  useEffect(() => {
    if (canVote) {
      setVote(undefined)
    }
  }, [canVote])

  if (!question) {
    return null
  }

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
      }}
    >
      <View
        style={{
          flex: isPortrait ? 0.3 : 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isPortrait && <Logo />}
      </View>
      <View
        style={{
          flex: isPortrait ? 0.7 : 1,
          alignSelf: 'stretch',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {QcmGamepad && (
          <QcmGamepad
            question={question}
            vote={vote}
            onVote={onVote}
            gameState={gameState}
            canVote={canVote}
          />
        )}
        {gameState === UnQuizzState.SHOW_ANSWER && (
          <View
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <Image
              style={{ width: normalize(80), height: normalize(80) }}
              source={question.checkAnswer(vote) ? goodAnswerImg : badAnswerImg}
            />
          </View>
        )}
      </View>
    </View>
  )
}

export default Gamepad
