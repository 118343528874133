import { View } from 'react-native'

import { space, useNormalize } from '@unboared/base-ui.all'

import { QCU } from './qcu'
import { QuestionGamepadProps } from '../types'
import { ProposalButtonGamepad } from '../../../components/buttons/proposal_button_gamepad'

/**
 * The component on the gamepad (related to the type of question)
 * @param {param QuestionGamepadProps} param props - properties for question gamepad
 * @returns the QCU gamepad component
 */
export const QcuGamepad = ({
  question,
  vote,
  onVote,
  canVote,
}: QuestionGamepadProps) => {
  const qcu = question as QCU

  return (
    <View
      style={{
        flex:1,
        width: '100%',
        height: '100%',
        alignSelf: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {qcu.getProposals().map((proposal, i) => (
        <ProposalButton
          key={i}
          i={i}
          onPress={() => onVote(proposal)}
          disable={vote !== proposal && (vote !== undefined || !canVote)}
        />
      ))}
    </View>
  )
}

const ProposalButton = ({ i, onPress, disable }: any) => {
  const { normalize } = useNormalize()

  return (
    <View
      style={{
        width: '50%',
        alignSelf: 'stretch',
        padding: normalize(space.smaller),
      }}
    >
      <ProposalButtonGamepad id={i} onPress={onPress} disable={disable} />
    </View>
  )
}
