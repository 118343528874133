import { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import MainApp from './src/app'

import { i18n, unboaredDarkTheme } from '@unboared/base-ui.all'
import { useLanguage } from '@unboared/utils.language'

import { en, fr } from './src/translations'
import { useAnalyticsAccessEvent } from './src/services/analytics'
import { getBrowserName, getOS } from './src/utils'

i18n.translations = { en, fr }
SplashScreen.preventAutoHideAsync()

export default function App() {
  useAnalyticsAccessEvent(`browser/${getBrowserName()}`)
  useAnalyticsAccessEvent(`OS/${getOS()}`)

  // Load fonts
  const [fontsLoaded] = useFonts({
    MontserratBold: require('./assets/fonts/Montserrat/Montserrat-Bold.ttf'),
    OpenSansRegular: require('./assets/fonts/OpenSans/OpenSans-Regular.ttf'),
    OpenSansBold: require('./assets/fonts/OpenSans/OpenSans-Bold.ttf'),
    OpenSansSemiBold: require('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf'),
  })

  // Set default language
  const { setLanguage } = useLanguage()
  useEffect(() => {
    setLanguage('fr')
  }, [])

  // Show splash screen on font loaded
  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync()
    }
  }, [fontsLoaded])

  if (!fontsLoaded) {
    return null
  }

  return (
    <SafeAreaProvider>
      <View
        style={{
          width: '100%',
          height: '100%',
        }}
        onLayout={onLayoutRootView}
      >
        <MainApp />
      </View>
    </SafeAreaProvider>
  )
}
