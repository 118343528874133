import { Pressable, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'

import {
  usePlayers,
  useScene,
  useListenerFrom,
  useCustomDeviceStateProperty,
} from '@unboared/lib'

import { UnQuizzState, useUnQuizzGame } from './UnQuizz'
import { Quizz } from '../../../model/quizz/Quizz'
import { ShowReadyScreen } from './screen_state/show_ready_screen'
import { ShowQuestionScreen } from './screen_state/show_question_screen'
import { ShowProposalsScreen } from './screen_state/show_proposals_screen'
import { ShowAnswerScreen } from './screen_state/show_answer_screen'

const ScreenGame = ({ quizz }: { quizz: Quizz }) => {
  // La lib
  const { getDeviceID, getGamepadIDs } = usePlayers()
  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty()
  const { loadScene } = useScene()

  // L'état local du jeu
  const { state, questionIndex, nextState } = useUnQuizzGame()

  // Les réponses
  const [numCorrect, setNumCorrect] = useState(0)
  const [votes, setVotes] = useState({})
  const [scores, setScores] = useState({})

  // Update question type on gamepads
  useEffect(() => {
    if (quizz !== undefined && questionIndex !== undefined) {
      loadCustomDeviceStateProperty(getDeviceID(), 'question', {
        ...quizz.getQuestion(questionIndex),
        type: quizz.getQuestionType(questionIndex),
      })
    }
  }, [quizz, questionIndex])

  // Update game state on gamepads
  useEffect(() => {
    if (state !== undefined) {
      loadCustomDeviceStateProperty(getDeviceID(), 'state', state)
    }
  }, [state])

  useEffect(() => {
    if (
      state === UnQuizzState.SHOW_PROPOSALS &&
      getGamepadIDs().length > 0 &&
      getGamepadIDs().length === Object.keys(votes).length
    ) {
      nextState()
    }
  }, [votes, getGamepadIDs, nextState])

  const callAtTheEnd = () => {
    loadCustomDeviceStateProperty(getDeviceID(), 'final_scores', scores)
    loadCustomDeviceStateProperty(
      getDeviceID(),
      'numQuestions',
      quizz.getNumQuestions(),
    )
    loadScene('results')
  }

  // Mets en mémoire les votes, les scores perçus par le joueur votant
  useListenerFrom('vote', (from: string, data: any) => {
    setVotes((prevVotes) => {
      return Object.assign({ ...prevVotes }, { [from]: data.vote })
    })
  })

  // // mets à jour les scores des joueurs quand la "manche" se termine
  useEffect(() => {
    if (state == UnQuizzState.SHOW_ANSWER) {
      setScores((prevScores: any) => {
        const newScores = { ...prevScores }
        setVotes((votes) => {
          for (let [deviceID, vote] of Object.entries(votes)) {
            if (!newScores[deviceID]) {
              newScores[deviceID] = 0
            }
            if (quizz.checkAnswer(questionIndex, vote)) {
              newScores[deviceID] = newScores[deviceID] + 1
            }
          }
          return votes
        })
        return newScores
      })
    }
  }, [state, questionIndex])

  // réinitialise les votes quand on passe au son suivant
  useEffect(() => {
    setVotes({})
    setNumCorrect(0)
  }, [questionIndex])

  console.log(`Status : ${state}`)

  switch (state) {
    case UnQuizzState.SHOW_READY:
      return <ShowReadyScreen quizz={quizz} startQuizz={nextState} />
    case UnQuizzState.SHOW_QUESTION:
      return (
        <ShowQuestionScreen
          quizz={quizz}
          indexQuestion={questionIndex}
          startNextState={nextState}
        />
      )
    case UnQuizzState.SHOW_PROPOSALS:
      return (
        <ShowProposalsScreen
          quizz={quizz}
          indexQuestion={questionIndex}
          startNextState={nextState}
        />
      )
    case UnQuizzState.SHOW_ANSWER:
      return (
        <ShowAnswerScreen
          quizz={quizz}
          indexQuestion={questionIndex}
          startNextState={nextState}
          onShowResults={callAtTheEnd}
          answers={votes}
        />
      )

    default:
      break
  }
}

export default ScreenGame
