import { createGlobalStorage } from '@unboared/utils.storage'

/* The popup state type  */
export type PopupState = {
    content: any;
    onClose?: () => void;
    open: (content: any, onClose?: () => void) => void;
    close: () => void;
    isOpen: () => boolean;
    setOnClose: (customOnClose: () => void) => void;
}

/* The initial storage state */
export const initialPopupState = {
    content: undefined,
    onClose: () => { },
}

/* The store that keep informations about the global popup */
export const useGlobalPopup = createGlobalStorage<PopupState>(
    (set: any, get: any) => ({
        ...initialPopupState,
        setOnClose: (customOnClose: () => void) => {
            set((state: PopupState) => ({ ...state, onClose: customOnClose }))
        },
        isOpen: () => {
            const { content } = get()
            return (content !== initialPopupState.content)
        },
        open: (content: any, onClose: () => void = () => { }) => {
            set((state: PopupState) => ({ ...state, content: content, onClose: onClose }))
        },
        close: () => {
            const onClose = get().onClose
            onClose()
            set({ ...initialPopupState })
        }
    })
)