import { space, useNormalize, useTheme } from '@unboared/base-ui.all'
import { View } from 'react-native'

/** The main container of the gamepad app */
export default function GamepadContainer({ style, children }: any) {
  const theme = useTheme()
  const { normalize } = useNormalize()

  return (
    <View
      style={[{
        flex: 1,
        alignItems: 'center',
        justifyContent:'center',
        backgroundColor: theme.backgroundColor,
        padding: normalize(space.small),
      }, style]}
    >
      {children}
    </View>
  )
}
