import { Button, Heading, space, useNormalize } from '@unboared/base-ui.all'
import { View } from 'react-native'
import { Quizz } from '../../../../model/quizz/Quizz'
import { TopRightQuestionIndex } from './show_question_index'

export const ShowAnswerScreen = ({
  quizz,
  answers,
  indexQuestion,
  startNextState,
  onShowResults,
}: {
  quizz: Quizz
  answers: any
  indexQuestion: number
  startNextState: () => void
  onShowResults: () => void
}) => {
  const { normalize } = useNormalize()
  let AnswerComponent = quizz.getQuestion(indexQuestion).getAnswerComponent()

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TopRightQuestionIndex
        index={indexQuestion}
        total={quizz.getNumQuestions()}
      />
      <View
        style={{ flex: 0.4, alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Heading
          style={{ textAlign: 'center', marginBottom: normalize(space.medium) }}
          text={quizz.getQuestionStatement(indexQuestion)}
        />
      </View>
      <View
        style={{
          flex: 0.6,
          alignSelf: 'stretch',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <AnswerComponent
          question={quizz.getQuestion(indexQuestion)}
          answers={answers}
        />
        <Button
          textPreset="medium"
          onPress={
            quizz.getNumQuestions() === indexQuestion + 1
              ? onShowResults
              : startNextState
          }
          size={12}
          text={
            quizz.getNumQuestions() === indexQuestion + 1
              ? 'Résultats'
              : 'Question Suivante'
          }
        />
      </View>
    </View>
  )
}
