import { useEffect, useState } from 'react'
import { View } from 'react-native'
import {
  Heading,
  space,
  useTheme,
  TextInput,
  useNormalize,
} from '@unboared/base-ui.all'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { DropDown } from '../../../components/input/dropdown'

export const QcuCreator = ({ question, setQuestion }: any) => {
  const { normalize } = useNormalize()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([
    { label: 'A', value: 0 },
    { label: 'B', value: 1 },
    { label: 'C', value: 2 },
    { label: 'D', value: 3 },
  ])

  useEffect(() => {
    if (question.proposals === undefined) {
      setQuestion((old: any) => ({
        ...old,
        proposals: ['', '', '', ''],
      }))
    }
  }, [question])

  useEffect(() => {
    if (question.answer === undefined || typeof question.answer !== 'string') {
      setQuestion((old: any) => ({
        ...old,
        answer: '',
      }))
    }
  }, [question])

  const updateProposal = (proposalIndex: number, newText: string) => {
    setQuestion((old: any) => {
      let newQuestion = { ...old }
      let oldProposal = newQuestion.proposals[proposalIndex]
      if (proposalIndex < newQuestion.proposals.length) {
        newQuestion.proposals[proposalIndex] = newText
        if (oldProposal == old.answer) {
          newQuestion.answer = newText
        }
      }
      return newQuestion
    })
  }

  const updateAnswer = (callback: any) => {
    const answer = callback()
    setQuestion((old: any) => {
      return { ...old, answer: question.proposals[answer] }
    })
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {[0, 1, 2, 3].map((proposal, i) => (
          <View
            key={i}
            style={{ width: '50%', padding: normalize(space.smaller) }}
          >
            <ProposalButton id={i}>
              <TextInput
                containerStyle={{
                  width: '100%',
                  alignItems: 'center',
                }}
                style={{
                  width: '100%',
                  borderWidth: 0,
                  outline: 'none',
                  fontFamily: theme.secondaryTitleFont,
                  fontSize: normalize(theme.sizeH2),
                }}
                placeholder={`Indiquez réponse ${String.fromCharCode(65 + i)}`}
                text={question.proposals ? question.proposals[i] : ''}
                onChangeText={(text: string) => updateProposal(i, text)}
              />
            </ProposalButton>
          </View>
        ))}
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: normalize(space.small),
          flexDirection: 'row',
        }}
      >
        <Heading
          style={{ marginRight: normalize(space.small) }}
          type="h2"
          text="La bonne réponse est:"
        />
        <View style={{}}>
          <DropDown
            placeholder="?"
            open={open}
            value={
              question.proposals &&
              question.proposals.findIndex(
                (element) => element === question.answer,
              )
            }
            items={items}
            setOpen={setOpen}
            setValue={updateAnswer}
            setItems={setItems}
          />
        </View>
      </View>
    </View>
  )
}
