import {
  Button,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all'
import { View } from 'react-native'
import { Quizz } from '../../../../model/quizz/Quizz'

export const ShowReadyScreen = ({
  quizz,
  startQuizz,
}: {
  quizz: Quizz
  startQuizz: () => void
}) => {
  const { normalize } = useNormalize()
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Heading
        style={{
          marginBottom: normalize(space.smaller),
          fontFamily: 'OpenSansBold',
        }}
        text={quizz.getName()}
      />
      <Heading
        style={{
          marginBottom: normalize(space.smaller),
        }}
        type="h3"
        text={`${quizz.getNumQuestions()} questions`}
      />
      <Text
        style={{
          marginBottom: normalize(space.smaller),
        }}
        text={quizz.getDescription()}
      />
      <Button
        textPreset="medium"
        style={{
          marginBottom: normalize(space.smaller),
        }}
        text="Démarrer"
        size={14}
        onPress={startQuizz}
      />
    </View>
  )
}
