import React from 'react'
import { View, ScrollView } from 'react-native'

import {
  Text,
  Heading,
  PortraitMobileLayout,
  useTranslate,
  space,
  useNormalize,
  base_color,
} from '@unboared/base-ui.all'
import { ConnectedPlayers } from '@unboared/design.all'
import { usePlayers } from '@unboared/lib'

const WaitingMessage = ({ ...props }: any) => {
  return (
    <View style={{ flex: 1, justifyContent: 'space-evenly' }} {...props}>
      <Heading type="h2">Vous avez rejoins la session avec succès.</Heading>
      <Text style={{ color: base_color.light_secondary }}>
        Attendez que le Quizz commence.
      </Text>
    </View>
  )
}

export const WaitingView = () => {
  const { getPlayers, getColor, getUsername, getMasterID } = usePlayers()
  const masterID = getMasterID()
  const { normalize } = useNormalize()

  return (
    <PortraitMobileLayout
      header={
        <WaitingMessage
          masterColor={getColor(masterID)}
          masterName={getUsername(masterID)}
        />
      }
      flex={{ header: 50, content: 50, footer: 0 }}
      contentStyle={{ alignItems: 'flex-start' }}
    >
      <Text
        preset="caption"
        tx="screen.gamesPage.connectedPlayers"
        style={{ color: base_color.light_tertiary, alignSelf: 'center' }}
      />
      <ConnectedPlayers
        style={{
          flex: 1,
          marginLeft: normalize(space.small),
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
        players={getPlayers()}
        masterID={undefined}
        size={30}
      />
    </PortraitMobileLayout>
  )
}
