import { View } from 'react-native'
import { useTimer } from '@unboared/design.all'
import { Heading, space, useNormalize } from '@unboared/base-ui.all'

import { Quizz } from '../../../../model/quizz'
import { TopRightQuestionIndex } from './show_question_index'

export const ShowQuestionScreen = ({
  quizz,
  indexQuestion,
  startNextState,
}: {
  quizz: Quizz
  indexQuestion: number
  startNextState: () => void
}) => {
  const { normalize } = useNormalize()
  const time = useTimer(quizz.getWaitBeforeProposals(), startNextState)

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TopRightQuestionIndex
        index={indexQuestion}
        total={quizz.getNumQuestions()}
      />
      <View
        style={{ flex: 0.4, alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Heading
          style={{ textAlign: 'center', marginBottom: normalize(space.medium) }}
          text={quizz.getQuestionStatement(indexQuestion)}
        />
      </View>
      <View style={{ flex: 0.6, alignItems: 'center' }}></View>
    </View>
  )
}
