import React, { useEffect, useState } from 'react'
import { View, Image, ScrollView } from 'react-native'

import { space, Heading, useNormalize, Button } from '@unboared/base-ui.all'

import {
  useCustomDeviceStateProperty,
  usePlayers,
  useScene,
} from '@unboared/lib'

import {
  FirstPositionScore,
  SecondPositionScore,
  ThirdPositionScore,
} from './score/PodiumScore'

import PositionScore from './score/PositionScore'

import { Quizz } from '../../model/quizz'
import { useLinkTo } from '@react-navigation/native'
import { ActivityIndicator } from '../../components/loaders'

type ScreenProps = {
  quizz: Quizz
}

const Screen = ({ quizz }: ScreenProps) => {
  const linkTo = useLinkTo()
  const { normalize } = useNormalize()
  const { loadScene } = useScene()
  const { getDeviceID, getPlayer, getGamepadIDs } = usePlayers()

  const [sortedPlayers, setSortedPlayers] = useState<any>()

  const {
    getCustomDeviceStateProperty,
    loadCustomDeviceStateProperty,
  } = useCustomDeviceStateProperty()

  const scores = getCustomDeviceStateProperty(getDeviceID(), 'final_scores')

  useEffect(() => {
    const sortedPlayers = Object.entries(scores)
      .sort(function (a: any, b: any) {
        return b[1] - a[1]
      })
      .reduce((previousValue, [deviceID, score]) => {
        let current
        if (getGamepadIDs().includes(deviceID)) {
          current = getPlayer(deviceID)
        }
        if (current) {
          previousValue.push({ ...current, deviceID: deviceID, score: score })
        }
        return previousValue
      }, [])
    setSortedPlayers(sortedPlayers)
  }, [])

  useEffect(() => {
    if (sortedPlayers !== undefined) {
      loadCustomDeviceStateProperty(
        getDeviceID(),
        'sortedPlayers',
        sortedPlayers,
      )
    }
  }, [sortedPlayers])

  if (!sortedPlayers) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Heading tx="screen.endgame.title" />
      <View
        style={{
          width: normalize(500),
          marginVertical: normalize(space.large),
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-end',
        }}
      >
        {(sortedPlayers.length > 1 && (
          <SecondPositionScore
            player={sortedPlayers[1]}
            score={sortedPlayers[1].score}
            maxScore={quizz.getNumQuestions()}
            size={65}
          />
        )) || <View style={{ width: normalize(35) }}></View>}
        {sortedPlayers.length > 0 && (
          <FirstPositionScore
            player={sortedPlayers[0]}
            score={sortedPlayers[0].score}
            maxScore={quizz.getNumQuestions()}
            size={90}
          />
        )}
        {(sortedPlayers.length > 2 && (
          <ThirdPositionScore
            player={sortedPlayers[2]}
            score={sortedPlayers[2].score}
            maxScore={quizz.getNumQuestions()}
            size={65}
          />
        )) || <View style={{ width: normalize(35) }}></View>}
      </View>
      <ScrollView style={{ maxHeight: normalize(3 * 60) }}>
        {sortedPlayers.slice(3).map((player, i) => (
          <PositionScore
            position={i + 4}
            player={player}
            score={player.score}
            maxScore={quizz.getNumQuestions()}
            size={normalize(20)}
            width={350}
          />
        ))}
      </ScrollView>
      <View
        style={{
          marginTop: normalize(space.small),
          flexDirection: 'row',
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          textPreset="medium"
          style={{
            width: '40%',
          }}
          text="Fermer le Quizz"
          onPress={() => {
            loadScene('connexion')
            linkTo('/me')
          }}
          size={12}
        />
      </View>
    </View>
  )
}

export default Screen
