import React from "react";

import ConnexionGamepad from '../Connexion/Gamepad'
import ConnexionScreen from '../Connexion/Screen'

import ResultGamepad from '../Results/Gamepad'
import ResultScreen from '../Results/Screen'

import InQuizzGamepad from '../inQuizz/Gamepad2'
import InQuizzScreen from '../inQuizz/Screen'


const SCENE = {
    "connexion": {
        gamepad: <ConnexionGamepad />,
        screen: (quizz) => (<ConnexionScreen quizz={quizz} />),
    },
    "inQuizz": {
        gamepad: <InQuizzGamepad />,
        screen: (quizz) => (<InQuizzScreen quizz={quizz} />)
    },
    "results": {
        gamepad: <ResultGamepad />,
        screen: (quizz) => (<ResultScreen quizz={quizz} />)
    },
}

export const gamepadForScene = (scene) => {
    return SCENE[scene].gamepad
}

export const screenForScene = (scene, quizz) => {
    return SCENE[scene].screen(quizz)
}