import React, { useEffect } from 'react'
import { Platform, View } from 'react-native'
// import { useSearchParams } from 'react-router-dom'

/* Import Unboared lib */
import { UnboaredGame, CommunicationConfig, useScene } from '@unboared/lib'

/* Import config and utils */
import { LoadingPage } from './components/loading_page'
import { getGamepadURLFormat } from './config/Const'
import { DEFAULT_SCENE, GAME_NAME, PROD_SOCKET_SERVER } from './config/Const'

/* Import screens */
import { screenForScene } from './config'

/* The hosting application  */
var host =
  Platform.OS === 'web'
    ? window.location.protocol + '//' + window.location.host
    : 'https://www.unquizz.com/'

/* The main screen app */
export default function QuizzScreen({ quizz }: any) {
  if (!quizz) {
    return <LoadingPage infos="Récupération des données du quizz..." />
  }

  return (
    <UnboaredGame
      name={GAME_NAME}
      initialScene={DEFAULT_SCENE}
      loader={<LoadingPage infos="Création de la session de présentation..." />}
      config={CommunicationConfig.getSocketIO('screen', PROD_SOCKET_SERVER, {
        url: getGamepadURLFormat(host),
      })}
    >
      <ScreenSceneManager quizz={quizz} />
    </UnboaredGame>
  )
}

export const ScreenSceneManager = ({ quizz }: any) => {
  const { getScene, sceneComponent, loadScene } = useScene()

  useEffect(() => {
    loadScene(DEFAULT_SCENE)
  }, [])

  if (!getScene()) {
    return <LoadingPage infos="Loading scene" />
  }

  if (sceneComponent) {
    return sceneComponent
  }

  return screenForScene(getScene(), quizz)
}
