import { Heading, size, useNormalize } from '@unboared/base-ui.all'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'

export const UnQuizzTimer = ({ duration, onComplete }: any) => {
  const { normalize } = useNormalize()
  return (
    <CountdownCircleTimer
      isPlaying
      duration={duration}
      colors={['#FFFFFF', '#FFFFFF']}
      colorsTime={[7, 0]}
      onComplete={onComplete}
      strokeWidth={normalize(7)}
      trailStrokeWidth={normalize(size.borderWidth.tiny)}
      size={normalize(80)}
    >
      {({ remainingTime }) => <Heading type="h2">{remainingTime}</Heading>}
    </CountdownCircleTimer>
  )
}
