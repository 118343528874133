import { Heading, space } from '@unboared/base-ui.all'
import { useNormalize } from '@unboared/utils.scale'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { ProposalButton } from '../../../components/buttons/proposal_button'
import { DropDown } from '../../../components/input/dropdown'

export const VraiFauxCreator = ({ question, setQuestion }: any) => {
  const { normalize } = useNormalize()
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([
    { label: 'VRAI', value: 'vrai' },
    { label: 'FAUX', value: 'faux' },
  ])

  useEffect(() => {
    if (question.answer === undefined || typeof question.answer !== 'string') {
      setQuestion((old: any) => ({
        ...old,
        answer: 'vrai',
      }))
    }
  }, [question])

  const updateAnswer = (callback: any) => {
    const answer = callback()
    setQuestion((old: any) => {
      return { ...old, answer: answer }
    })
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '50%',
            minWidth: normalize(160),
            padding: normalize(space.smaller),
          }}
        >
          <ProposalButton id={0}>
            <Heading type="h3" text="VRAI" />
          </ProposalButton>
        </View>
        <View
          style={{
            width: '50%',
            minWidth: normalize(160),
            padding: normalize(space.smaller),
          }}
        >
          <ProposalButton id={1}>
            <Heading type="h3" text="FAUX" />
          </ProposalButton>
        </View>
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: normalize(space.small),
          flexDirection: 'row',
        }}
      >
        <Heading
          style={{ marginRight: normalize(space.small) }}
          type="h2"
          text="La bonne réponse est:"
        />
        <View style={{}}>
          <DropDown
            placeholder="?"
            open={open}
            value={
              typeof question.answer === "string" && question.answer
            }
            items={items}
            setOpen={setOpen}
            setValue={updateAnswer}
            setItems={setItems}
          />
        </View>
      </View>
    </View>
  )
}
