import { useState } from 'react'
import { Image, View } from 'react-native'
import {
  TextInputWithButton,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all'

import { usePlayers } from '@unboared/lib'

import goodAnswerImg from '../../../assets/img/goodAnswer.png'
import badAnswerImg from '../../../assets/img/badAnswer.png'
import { UnQuizzState } from '../../../quizz/inQuizz/components/UnQuizz'

export const QrGamepad = ({
  vote,
  onVote,
  gameState,
  canVote,
  goodAnswers,
}: any) => {

  const { getDeviceID } = usePlayers()
  const { normalize } = useNormalize()
  const theme = useTheme()

  const [myAnswer, setMyAnswer] = useState('')

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {canVote && (
        <TextInputWithButton
          style={{
            width: '100%',
            fontSize: normalize(theme.sizeH2),
          }}
          placeholder={`Indiquez votre réponse`}
          text={myAnswer}
          submitText='Valider'
          onChangeText={setMyAnswer}
          onSubmit={() => onVote(myAnswer)}
        />
      )}
      {gameState === UnQuizzState.SHOW_ANSWER && goodAnswers && (
        <View
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          {goodAnswers && Object.keys(goodAnswers).includes(getDeviceID()) && (
            <Image
              style={{ width: normalize(80), height: normalize(80) }}
              source={goodAnswers[getDeviceID()] ? goodAnswerImg : badAnswerImg}
            />
          )}
        </View>
      )}
    </View>
  )
}
