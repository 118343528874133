import { QuestionType } from "../types"
import { Question } from "../Question"
import { QrProposals } from "./QrProposals"
import { QrCreator } from "./QrCreator"
import { QrAnswer } from "./QrAnswer"
import { QrGamepad } from "./QrGamepad"


/**
 * The model related to the "Question/Réponse". 
 */
export class QR extends Question {
    answer: string
    acceptables: Array<string>

    constructor(question: string, duration: number, anecdote: string, answer: string, acceptables: Array<string>) {
        super(question, duration, anecdote)
        this.answer = answer
        this.acceptables = acceptables
    }

    checkAnswer(answer: unknown) {
        return (typeof answer === 'string') && this.isAcceptable(answer)
    }

    includeAnswer(answer: string) {
        return this.acceptables.includes(answer)
    }

    getAnswer(): string {
        return this.answer
    }

    isAcceptable(answer: string): boolean {
        return this.acceptables.includes(answer)
    }

    getProposalsComponent(): any {
        return QrProposals
    }

    getAnswerComponent(): any {
        return QrAnswer
    }

    getCreatorComponent(): any {
        return QrCreator
    }

    getGamepadComponent(): any {
        return QrGamepad
    }

    getType(): QuestionType {
        return QuestionType.QR
    }
}
