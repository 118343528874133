import { useState } from 'react'
import { Pressable, View } from 'react-native'
import {
  accent_color,
  Button,
  Heading,
  size,
  space,
  TextInput,
  useNormalize,
} from '@unboared/base-ui.all'
import { useScreenInfos } from '@unboared/utils.orientation'

import { DropDown } from '../../../components/input/dropdown'

import { QuestionType } from '../../../model/question'
import { QcuCreator } from '../../../model/question/qcu'
import { VraiFauxCreator } from '../../../model/question/vrai_faux'
import { QcmCreator } from '../../../model/question/qcm'
import { QrCreator } from '../../../model/question/qr'

import { DEFAULT_INITIAL_QUESTION } from '../../../model/question/types'
import { NavigationPanel2 } from './navigation_panel'

export const QuestionsCreation = ({
  questions,
  setQuestions,
  onCancel,
  onSave,
  onLaunch,
  goToTitle,
}: any) => {
  const { normalize } = useNormalize()
  const [indexQ, setIndexQ] = useState(0)
  const { width } = useScreenInfos()

  const setQuestion = (callback: (oldQuestion: any) => any) => {
    setQuestions((state) => {
      let q = [...state]
      q[indexQ] = callback(q[indexQ])
      return q
    })
  }

  const addQuestion = () => {
    setQuestions((state) => {
      let q = [...state]
      q.push(DEFAULT_INITIAL_QUESTION)
      setIndexQ(q.length - 1)
      return q
    })
  }

  const removeQuestion = () => {
    setQuestions((state) => {
      let q = [...state]
      if (q.length > 1) {
        q.splice(indexQ, 1)
        setIndexQ(Math.max(0, indexQ - 1))
      }
      return q
    })
  }

  const moveQuestion = (fromIndex: number, toIndex: number) => {
    setQuestions((state: any) => {
      let q = [...state]

      if (q.length > 1) {
        q.splice(toIndex, 0, q.splice(fromIndex, 1)[0])
        setIndexQ(toIndex)
      }
      return q
    })
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        minWidth: normalize(300),
        maxWidth: normalize(0.9 * width),
      }}
    >
      <View>
        {/* <Image
          source={lineBar2}
          style={{
            width: normalize(LINE_BAR_WIDTH),
            height: normalize(LINE_BAR_HEIGHT),
          }}
        /> */}
      </View>

      <View style={{ zIndex: 99, elevation: 99 }}>
        <QuestionCreator
          question={questions[indexQ]}
          id={indexQ}
          setQuestion={setQuestion}
        />
      </View>
      <NavigationPanel2
        numQuestions={questions.length}
        activeQuestion={indexQ}
        removeQuestion={removeQuestion}
        addQuestion={addQuestion}
        onPressTitle={goToTitle}
        setIndexQuestion={setIndexQ}
        moveQuestion={moveQuestion}
      />
      <ActionButtons onCancel={onCancel} onSave={onSave} onLaunch={onLaunch} />
    </View>
  )
}

/**
 * Gère la question, son type et choisis le composant nécessaire pour remplir le formulaire.
 */
const QuestionCreator = ({ question, id, setQuestion }: any) => {
  const { normalize } = useNormalize()
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([
    { label: 'QCU', value: QuestionType.QCU },
    { label: 'QCM', value: QuestionType.QCM },
    { label: 'Vrai/Faux', value: QuestionType.VRAI_FAUX },
    // { label: 'QR', value: QuestionType.QR },
  ])

  const [openDuration, setOpenDuration] = useState(false)
  const [durations, setDurations] = useState([
    { label: '5 sec', value: 5 },
    { label: '10 sec', value: 10 },
    { label: '15 sec', value: 15 },
    { label: '20 sec', value: 20 },
    { label: '30 sec', value: 30 },
    { label: '45 sec', value: 45 },
    { label: '1 min', value: 60 },
  ])

  const updateQuestionDuration = (callback: any) => {
    const newDuration = callback()
    if (typeof newDuration === 'number' && newDuration > 0) {
      setQuestion((old: any) => {
        return { ...old, duration: newDuration }
      })
    }
  }
  const updateQuestionEnonce = (newText: string) => {
    setQuestion((old: any) => {
      return { ...old, question: newText }
    })
  }

  const updateAnecdote = (newText: string) => {
    setQuestion((old: any) => {
      return { ...old, anecdote: newText }
    })
  }

  const updateQuestionType = (callback: any) => {
    const type = callback()
    setQuestion((old: any) => {
      return { ...old, type: type }
    })
  }

  let QuestionCreator
  switch (question.type) {
    case QuestionType.QCU:
      QuestionCreator = QcuCreator
      break
    case QuestionType.QCM:
      QuestionCreator = QcmCreator
      break
    case QuestionType.VRAI_FAUX:
      QuestionCreator = VraiFauxCreator
      break
    case QuestionType.QR:
      QuestionCreator = QrCreator
      break
    default:
      QuestionCreator = QcuCreator
      break
  }

  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
      }}
    >
      <View
        style={{
          // width: '80%',
          flexDirection: 'row',
          elevation: 999,
          zIndex: 999,
        }}
      >
        <Heading
          style={{ flex: 1, marginHorizontal: normalize(space.small) }}
          type="h2"
          text={`Question ${id + 1}:`}
        />
        <View style={{}}>
          <DropDown
            placeholder="Type"
            open={open}
            value={question.type}
            items={items}
            setOpen={setOpen}
            setValue={updateQuestionType}
            setItems={setItems}
          />
        </View>
        <View style={{}}>
          <DropDown
            placeholder="Time"
            open={openDuration}
            value={question.duration}
            items={durations}
            setOpen={setOpenDuration}
            setValue={updateQuestionDuration}
            setItems={setDurations}
          />
        </View>
      </View>
      <TextInput
        style={{
          width: '100%',
          borderRadius: normalize(size.borderRadius.small),
          fontSize:normalize(14)
        }}
        containerStyle={{
          width: '100%',
          maxWidth: normalize(500),
          marginVertical: normalize(space.medium),
          alignItems: 'center',
        }}
        placeholder="Indiquez votre question ici..."
        text={question.question || ''}
        onChangeText={updateQuestionEnonce}
      />
      <View style={{ zIndex: 99, elevation: 99 }}>
        <QuestionCreator question={question} setQuestion={setQuestion} />
      </View>
      <TextInput
        style={{
          width: '100%',
          borderRadius: normalize(size.borderRadius.small),
          fontSize:normalize(14)
        }}
        containerStyle={{
          width: '100%',
          maxWidth: normalize(700),
          marginVertical: normalize(space.medium),
          alignItems: 'center',
        }}
        placeholder="Insérez une anecdote sur la réponse..."
        text={question.anecdote || ''}
        onChangeText={updateAnecdote}
      />
    </View>
  )
}

const ActionButtons = ({ onCancel, onSave, onLaunch }: any) => {
  const { normalize } = useNormalize()

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Pressable onPress={onCancel} style={{ alignItems: 'center' }}>
        <Heading type="h3">Annuler</Heading>
      </Pressable>
      <Button
        style={{
          marginLeft: normalize(space.small),
          width: '30%',
          maxWidth: normalize(250),
        }}
        text="ENREGISTRER"
        onPress={onSave}
      />
      <Button
        style={{
          marginLeft: normalize(space.small),
          backgroundColor: accent_color.success,
          maxWidth: normalize(250),
          width: '30%',
        }}
        text="LANCER QUIZZ"
        onPress={onLaunch}
      />
    </View>
  )
}
