import {
  Heading,
  Icon,
  Text,
  accent_color,
  space,
  useNormalize,
} from '@unboared/base-ui.all'
import { Pressable, View } from 'react-native'
import { UnQuizzContainer } from '../../../../components/container/unquizz_container'
import { useGlobalPopup } from '../../../../components/popup/global_popup'
import { DeleteConfirmationPopup } from './delete_confirmation_popup'

export const NavigationItem = ({ text, numQuestions, onPress, onRemove, active }: any) => {
  const { normalize } = useNormalize()
  const { open, close } = useGlobalPopup()

  const confirmDeletion = () => {
    open(
      <DeleteConfirmationPopup
        name={text}
        numQuestions={numQuestions}
        onRemove={() => {
          onRemove()
          close()
        }}
        onCancel={close}
      />,
    )
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {active && (
        <Pressable onPress={confirmDeletion}>
          <Icon
            name="io-trash"
            style={{
              position: 'absolute',
              width: '100%',
            }}
            color={accent_color.important}
          />
        </Pressable>
      )}
      <Pressable onPress={onPress}>
        <UnQuizzContainer
          style={[
            {
              flex: 1,
              flexDirection: 'row',
              width: normalize(80),
              marginRight: normalize(space.small),
              alignItems: 'center',
              justifyContent: 'center',
            },
            active ? { backgroundColor: accent_color.important } : {},
          ]}
        >
          <Text
            style={{
              fontWeight: 'bold',
              flex: 1,
              alignSelf: 'center',
              textAlign: 'center',
            }}
            text={text}
          />
          {text[0] === 'Q' && <Icon name="md-drag-indicator" size={12} />}
        </UnQuizzContainer>
      </Pressable>
    </View>
  )
}
