import { useState } from 'react'
import { QuizzCreationType } from '../../const'
import { InfosCreation } from './infos_creation'
import { QuestionsCreation } from './questions_creation'

/** 
 * The quizz edition component 
 */
export const QuizzEdition = ({
  title,
  setTitle,
  questions,
  setQuestions,
  durationBeforeStart,
  setDurationBeforeStart,
  onCancel,
  onSave,
  onLaunch
}: any) => {
  const [part, setPart] = useState(QuizzCreationType.INFOS)
  switch (part) {
    case QuizzCreationType.INFOS:
      return (
        <InfosCreation
          title={title}
          setTitle={setTitle}
          durationBeforeStart={durationBeforeStart}
          setDurationBeforeStart={setDurationBeforeStart}
          onCancel={onCancel}
          onValidate={() => setPart(QuizzCreationType.QUESTIONS)}
        />
      )

    case QuizzCreationType.QUESTIONS:
      return (
        <QuestionsCreation questions={questions} setQuestions={setQuestions} goToTitle={() => setPart(QuizzCreationType.INFOS)} onCancel={onCancel} onSave={onSave} onLaunch={onLaunch}/>
      )
  }
}
