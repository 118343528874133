import { useScreenScale } from '@unboared/base-ui.all'
import { useAnalyticsAccessEvent } from '../../services/analytics'
import { ModifyQuizzFor } from '../modify_quizz'

export function CreateQuizzScreen({ navigation, route }: any) {
  useAnalyticsAccessEvent('create_quizz')
  useScreenScale()

  return <ModifyQuizzFor navigation={navigation} route={route} />
}
