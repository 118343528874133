import React, { useState } from 'react'

// import { useFullscreen } from '@unboared/utils.fullscreen'
import { UserSelection } from './components/page/user_infos'

import { DEFAULT_SCENE, GAME_NAME, PROD_SOCKET_SERVER } from './config/Const'

import { Player } from '@unboared/native.types'
import { CommunicationConfig, UnboaredGame, useScene } from '@unboared/lib'
import { gamepadForScene } from './config'
import { LoadingPage } from './components/loading_page'
import { useGamepadScale } from '@unboared/base-ui.all'
import { GamepadHeader } from '../components/header'

export default ({ sessionID }: any) => {
  useGamepadScale()
  const [launched, setLaunched] = useState(false)
  // const { toggleFullscreen } = useFullscreen()
  const [currentPlayer, setCurrentPlayer] = useState<any>()

  const launchUI = (newPlayer: Player) => {
    setLaunched(true)
    // toggleFullscreen();
    setCurrentPlayer(newPlayer)
  }

  if (!launched) {
    return <UserSelection onSelected={launchUI} />
  }

  return (
    <UnboaredGame
      name={GAME_NAME}
      initialScene={DEFAULT_SCENE}
      loader={<LoadingPage infos="Connexion to the server..." />}
      config={CommunicationConfig.getSocketIO(
        'gamepad',
        PROD_SOCKET_SERVER,
        {
          id: sessionID,
        },
        currentPlayer,
      )}
    >
      <GamepadHeader />
      <GamepadSceneManager />
    </UnboaredGame>
  )
}

export const GamepadSceneManager = () => {
  const { getScene } = useScene()

  if (!getScene()) {
    return <LoadingPage infos="Loading parameters..." />
  }

  return gamepadForScene(getScene())
}
