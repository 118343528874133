import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme, TextInput, useNormalize } from '@unboared/base-ui.all'

export const QrCreator = ({ question, setQuestion }: any) => {
  const { normalize } = useNormalize()
  const theme = useTheme()

  useEffect(() => {
    if (question.answer === undefined || typeof question.answer !== 'string') {
      setQuestion((old: any) => ({
        ...old,
        answer: '',
      }))
    }
  }, [question])

  const updateAnswer = (text: string) => {
    setQuestion((old: any) => ({
      ...old,
      answer: text,
    }))
  }

  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minWidth: normalize(460),
      }}
    >
      <TextInput
        containerStyle={{
          width: '100%',
        }}
        style={{
          width: '100%',
          fontSize: normalize(theme.sizeH2),
        }}
        placeholder={`Indiquez la réponse`}
        text={question.answer || ''}
        onChangeText={(text: string) => updateAnswer(text)}
        label="Réponse:"
      />
    </View>
  )
}
