import { QCM } from "../qcm"
import { QuestionType } from "../types"
import { QcuCreator } from "./QcuCreator"
import { QcuProposals } from "./QcuProposals"
import { QcuAnswer } from "./QcuAnswer"
import { QcuGamepad } from "./QcuGamepad"

/**
 * The model related to the "Questions à choix unique".
 */
export class QCU extends QCM {
    constructor(question: string, duration: number, anecdote: string, answer: unknown, proposals: Array<unknown>) {
        super(question, duration, anecdote, [answer], proposals)
    }

    getProposalsComponent(): any {
        return QcuProposals
    }

    getCreatorComponent(): any {
        return QcuCreator
    }

    getAnswerComponent(): any {
        return QcuAnswer
    }

    getAnswer(): unknown {
        return this.answers[0]
    }

    getGamepadComponent(): any {
        return QcuGamepad
    }

    getType(): QuestionType {
        return QuestionType.QCU
    }
}

