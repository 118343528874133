import React, { useState } from 'react'
import { View, Image, ImageBackground, Platform } from 'react-native'
import Swiper from 'react-native-swiper/src'

import {
  Text,
  accent_color,
  TextInput,
  Icon,
  useNormalize,
  useTranslate,
  space,
  transparency,
  useTheme,
  Heading,
} from '@unboared/base-ui.all'

import { useAnalyticsAccessEvent } from '../../services/analytics'

const CODE_SESSION_LENGTH = 6

var host =
  Platform.OS === 'web'
    ? window.location.protocol + '//' + window.location.host
    : 'https://www.unquizz.com/'

/**
 * This this the page used by users to enter the session code on their phone
 */
export const GamepadAccessPage = () => {
  useAnalyticsAccessEvent('gamepad/access_with_code')

  const { translate } = useTranslate()
  const { normalize } = useNormalize()
  const theme = useTheme()

  const [code, setCode] = useState('')

  const onPress = (code: string) => {
    // linkTo(`/gamepad/${code}`);
    window.open(`${host}/gamepad/${code}`, '_self')
  }

  const onChange = (newText: string) => {
    setCode(newText)
    if (newText.length === CODE_SESSION_LENGTH) {
      onPress(newText)
    }
  }
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'center',
            marginTop: normalize(space.huge),
          }}
        >
          <Heading tx="gamepad.joinPage.title" />
        </View>

        <View>
          <SwiperInfos />
        </View>
        <View style={{ alignItems: 'center' }}>
          <TextInput
            containerStyle={{ transform: [{ scale: 1.5 }] }}
            label={translate('gamepad.joinPage.codeSession.title')}
            text={code}
            minLength={6}
            maxLength={6}
            placeholder={'0'.repeat(CODE_SESSION_LENGTH)}
            onChangeText={onChange}
            keyboardType="numeric"
            helperText={translate('gamepad.joinPage.codeSession.help')}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  flexRow: {
    flexDirection: 'row',
  },
  important: {
    color: accent_color.important,
  },
  wrapper: {},
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export const SwiperInfos = () => {
  const { normalize } = useNormalize()
  const theme = useTheme()
  const [index, setIndex] = useState(0)

  const styleText = {
    marginTop: normalize(20),
    textAlign: 'center',
    maxWidth: normalize(200),
    fontSize: normalize(12),
  }

  const styleImg = {
    height: 0.9 * normalize(155),
    width: 0.9 * normalize(267),
  }
  const styleImg3 = {
    height: 0.9 * normalize(127),
    width: 0.9 * normalize(194),
  }

  return (
    <Swiper
      index={index}
      style={styles.wrapper}
      buttonWrapperStyle={{ paddingHorizontal: normalize(space.medium) }}
      dotColor={transparency(theme.mode, 0.2)}
      activeDotColor={theme.textColor}
      height={normalize(300)}
      showsButtons
      loop={false}
      onIndexChanged={setIndex}
      prevButton={
        <Text style={{ color: theme.textColor, fontSize: normalize(40) }}>
          ‹
        </Text>
      }
      nextButton={
        <Text style={{ color: theme.textColor, fontSize: normalize(40) }}>
          ›
        </Text>
      }
    >
      <ImageBackground
        resizeMode="cover"
        source={require('./img/back.png')}
        testID="slide1"
        style={styles.slide}
      >
        <Image style={styleImg} source={require('./img/1.png')} />
        <Text style={styleText} tx="gamepad.joinPage.instruction1" />
      </ImageBackground>
      <ImageBackground
        resizeMode="cover"
        source={require('./img/back.png')}
        testID="slide2"
        style={styles.slide}
      >
        <Image style={styleImg} source={require('./img/2.png')} />
        <Text style={styleText} tx="gamepad.joinPage.instruction2" />
      </ImageBackground>
      <ImageBackground
        resizeMode="cover"
        source={require('./img/back.png')}
        testID="slide3"
        style={styles.slide}
      >
        <Image style={styleImg3} source={require('./img/3.png')} />
        <Text style={styleText}>
          <Text style={styleText} tx="gamepad.joinPage.instruction3-1" />
        </Text>
      </ImageBackground>
    </Swiper>
  )
}
